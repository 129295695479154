import { ReactElement } from 'react';
import styled from 'styled-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { getCurrentLocale, text } from '../../Localization/Localizer';
import { ReactHtmlParser } from '@breathelife/ui-components';

const LegalText = styled.p`
  color: ${(props) => props.theme.colors.grey[80]};
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
`;

const FooterContainer = styled.footer`
  width: 100%;
  text-align: center;
  padding: 32px 24px;
  margin-top: auto;
`;

export function Footer(): ReactElement {
  const { settings } = useCxSelector((store) => store.consumerFlow.settings);
  const language = getCurrentLocale();
  const consumerFooter = settings?.data.consumerFooter?.[language];

  const year = new Date().getFullYear();
  const legalText = consumerFooter?.replace(/{year}/gm, year.toString()) ?? text('home.legal', { year: year });

  return (
    <FooterContainer>
      <LegalText>
        <ReactHtmlParser html={legalText} />
      </LegalText>
    </FooterContainer>
  );
}
