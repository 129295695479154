import { ReactElement } from 'react';

import { getAllQuestions } from '@breathelife/questionnaire-engine';

import { Props as FormProps } from '../../Components/FormView/FormView';
import { text } from '../../Localization/Localizer';
import { NavigationButtons } from '../../Components/NavigationButtons/NavigationButtons';

type CustomVariantWrapperProps = {
  formViewProps: FormProps;
  children: ReactElement;
  useCustomNavigation?: boolean;
  customContentPosition?: 'top' | 'bottom';
  nextStepButtonText?: string;
};

export function CustomVariantWrapper(props: CustomVariantWrapperProps): ReactElement {
  const { renderingQuestionnaire, onSubmit, onBackButtonClick, landingStepsIds, renderingStepId, nextStepButtonText } =
    props.formViewProps;

  const questions = getAllQuestions(renderingQuestionnaire);
  const position = props.customContentPosition ?? 'bottom';

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      {position === 'top' && props.children}
      {questions.map(
        () => undefined,
        // <FieldGenerator
        //   key={question.id}
        //   context={RenderContext.web}
        //   question={question}
        //   locale={language}
        //   onAnswerChange={onAnswerChange}
        //   onAnswerComplete={onAnswerComplete}
        //   styleVariant={StyleVariant.consumer}
        //   iconMap={iconMap}
        //   onInfoIconClick={onInfoIconClick}
        //   onError={onError}
        // />
      )}
      {position === 'bottom' && props.children}
      {!props.useCustomNavigation && (
        <NavigationButtons
          onPreviousClick={onBackButtonClick ? onBackButtonClick : () => {}}
          hidePrevious={!!landingStepsIds?.includes(renderingStepId)}
          nextButtonText={nextStepButtonText || text('continue')}
          onNextClick={() => {}}
        />
      )}
    </form>
  );
}
