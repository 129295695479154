import { ReactElement, Fragment } from 'react';

import { CryptoMaterial } from '@breathelife/crypto-material';
import { SubmissionSubsectionLayout } from '@breathelife/questionnaire-engine';
import { Language, OutcomeCode } from '@breathelife/types';

import { text } from '../../../Localization/Localizer';
import { DeniedIcon, SuccessIcon } from './Icons';
import {
  ConfirmationSignatureContainer,
  Content,
  CryptoSignature,
  CryptoSignatureTitle,
  Subtitle,
  Title,
} from './Styles';
import { ReactHtmlParser } from '@breathelife/ui-components';

export type Props = {
  isLoading: boolean;
  isSubmissionSuccess: boolean;
  outcome?: OutcomeCode;
  language: Language;
  cryptoMaterial?: CryptoMaterial;
  confirmationNumber?: string;
  temporaryInsurancePdfUrl?: string;
  applicationPdfUrl?: string;
  layout?: SubmissionSubsectionLayout;
};

export function SubmissionWithFlowLayout(props: Props): ReactElement {
  const { outcome, cryptoMaterial } = props;
  return <Fragment>{outcome && <SubmissionInfo outcome={outcome} cryptoMaterial={cryptoMaterial} />}</Fragment>;
}

function SubmissionInfo(props: { outcome: OutcomeCode; cryptoMaterial?: CryptoMaterial }): ReactElement {
  const { outcome, cryptoMaterial } = props;

  const subtitle =
    outcome === OutcomeCode.denied ? (
      <ReactHtmlParser html={text('submitApplication.success.denied.moreInformation')} />
    ) : (
      <ReactHtmlParser html={text('submitApplication.success.contact')} />
    );

  return (
    <Content data-testresulttype={outcome}>
      {outcome === OutcomeCode.denied ? <DeniedIcon /> : <SuccessIcon />}
      <Title>{text(`submitApplication.success.${outcome}.title`)}</Title>
      <Subtitle>
        <ReactHtmlParser html={text(`submitApplication.success.${outcome}.subtitle`)} />
      </Subtitle>
      {cryptoMaterial && outcome !== OutcomeCode.denied && <ConfirmationSignature cryptoMaterial={cryptoMaterial} />}
      <Subtitle>{subtitle}</Subtitle>
    </Content>
  );
}

function ConfirmationSignature(props: { cryptoMaterial: CryptoMaterial }): ReactElement {
  return (
    <ConfirmationSignatureContainer>
      <CryptoSignatureTitle>{text('submitApplication.success.keys')}</CryptoSignatureTitle>
      <CryptoSignature>{props.cryptoMaterial.seed}</CryptoSignature>
      <CryptoSignature>{props.cryptoMaterial.signature}</CryptoSignature>
    </ConfirmationSignatureContainer>
  );
}
