import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { FormEvent, ReactElement, useContext } from 'react';

import config from '@breathelife/config/frontend';
import { getAllQuestions, QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { VersionedAnswers } from '@breathelife/types';

import { Spinner } from '../../Components/Spinner';
import { CarrierContext } from '../../Context/CarrierContext';
import { useStep } from '../../Hooks/useStep';
import { BaseHeader } from '../../Layouts/Centered/Header';
import { text } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { Card, CardContent, CardSubtitle, CardTitle, Content, MainImage, StartButton } from './Styles';

type GenericLinkLandingProps = {
  mainImage?: string;
  questionnaireEngine: QuestionnaireEngine;
  displayErrors?: boolean;
  submitAnswers: (answer: VersionedAnswers) => void;
} & StepProps;

export function GenericLinkLanding(props: GenericLinkLandingProps): ReactElement {
  const { questionnaireEngine, displayErrors = false, submitAnswers, isLoading } = props;

  const { renderingQuestionnaire, renderingStep, onSubmit } = useStep(
    questionnaireEngine,
    displayErrors,
    submitAnswers,
  );

  // const language = shortLocale();
  const { images } = useContext(CarrierContext);

  const landingPageImage = _.get(images, 'genericPublicLink.landingPageImage') ?? '';

  const questions = getAllQuestions(renderingQuestionnaire);

  const title = text('genericLinkLanding.title') || renderingStep.title;
  const subtitle = text('genericLinkLanding.subtitle') || renderingStep.text;

  const onSubmitForm: (event: FormEvent<HTMLFormElement>) => void = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  if (isLoading) return <Spinner />;

  return (
    <Box component='main' width='100%'>
      <BaseHeader hideScheduleCall hideProgress />
      <Card>
        <MainImage
          $image={landingPageImage}
          $displayMobile={config.get('ui.displayGenericLinkLandingPageImage.mobile') ?? true}
        />
        <CardContent>
          <Content>
            <CardTitle level={1} center>
              {title}
            </CardTitle>
            <CardSubtitle level={4} center>
              {subtitle}
            </CardSubtitle>
            <form onSubmit={onSubmitForm} noValidate={true}>
              {questions.map(() => undefined)}
              <StartButton type='submit'>
                <span>{text('genericLinkLanding.submitButton')}</span>
              </StartButton>
            </form>
          </Content>
        </CardContent>
      </Card>
    </Box>
  );
}
