import _ from 'lodash';
import { SetStateAction, Dispatch, useCallback, useEffect, useState } from 'react';

import {
  getAllSubsections,
  isRenderingQuestionnaireComplete,
  OnAnswerChange,
  QuestionnaireEngine,
  RenderingQuestionnaire,
} from '@breathelife/questionnaire-engine';
import { VersionedAnswers } from '@breathelife/types';

import { RenderingStep } from '../Models/Step';

type StepProps = {
  setLocalVersionedAnswers: Dispatch<SetStateAction<VersionedAnswers>>;
  localVersionedAnswers: VersionedAnswers;
  setSubmitFormRequested: Dispatch<SetStateAction<boolean>>;
  renderingQuestionnaire: RenderingQuestionnaire;
  renderingStep: RenderingStep;
  /**
   * Attempt to submit the answer for the current step.
   * Returns `true` if the submission is successful. `false` otherwise
   */
  onSubmit: () => boolean;
  onAnswerChange: OnAnswerChange;
};

export function useStep(
  questionnaireEngine: QuestionnaireEngine,
  displayErrors: boolean,
  submitAnswers: (versionedAnswers: VersionedAnswers) => void,
): StepProps {
  const [localVersionedAnswers, setLocalVersionedAnswers] = useState<VersionedAnswers>(
    questionnaireEngine.getAnswerResolverInstance().dump(),
  );

  const [displayValidationErrors, setDisplayValidationErrors] = useState(displayErrors);
  const [submitFormRequested, setSubmitFormRequested] = useState(false);

  const onSubmit = useCallback(() => {
    if (!isRenderingQuestionnaireComplete(questionnaireEngine.renderingQuestionnaire)) {
      setDisplayValidationErrors(true);
      return false;
    }

    submitAnswers(localVersionedAnswers);
    return true;
  }, [localVersionedAnswers, questionnaireEngine, submitAnswers, displayValidationErrors, setDisplayValidationErrors]);

  // Handle delayed submit actions from callbacks that depend on state changes to localAnswers object
  useEffect(() => {
    if (!submitFormRequested) return;
    onSubmit();
    setSubmitFormRequested(false);
  }, [submitFormRequested, setSubmitFormRequested, onSubmit]);

  const allSteps: RenderingStep[] = getAllSubsections(questionnaireEngine.renderingQuestionnaire);
  const renderingStep = _.first(allSteps);
  if (!renderingStep) throw new Error('Rendering step not found');

  return {
    setLocalVersionedAnswers,
    localVersionedAnswers,
    setSubmitFormRequested,
    renderingQuestionnaire: questionnaireEngine.renderingQuestionnaire,
    renderingStep,
    onSubmit,
    onAnswerChange: () => {},
  };
}
