import { ReactElement } from 'react';

import { Box } from '@breathelife/mui';
import { RunningText, Title, ReactHtmlParser } from '@breathelife/ui-components';

import { ConfirmationIcon } from './ConfirmationIcon';
import { ConfirmationIconContainer } from './Styles';

type Props = {
  title: string;
  subtitle: string;
};

export function Confirmation({ title, subtitle }: Props): ReactElement {
  return (
    <Box my={3} textAlign='center'>
      <ConfirmationIconContainer>
        <ConfirmationIcon />
      </ConfirmationIconContainer>
      <Title level={2} center>
        {title}
      </Title>
      <Box mt={2}>
        <RunningText center>
          <ReactHtmlParser html={subtitle} />
        </RunningText>
      </Box>
    </Box>
  );
}
