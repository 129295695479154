import { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import {
  NodeIdToAnswerPathMap,
  PriceUpdateWarningRenderingSubsection,
  QuestionnaireEngine,
  QuoterRenderingSubsection,
  SubmissionRenderingSubsection,
  SubmissionSubsectionLayout,
} from '@breathelife/questionnaire-engine';
import { SubsectionVariant, ConsumerFlow, QuestionnaireBlueprint } from '@breathelife/types';
import { Icon, ResponsiveProps, withResponsive } from '@breathelife/ui-components';

import { AddonsViewContainer } from '../../Components/AddonsView/AddonsViewContainer';
import { BlockingContainer } from '../../Components/BlockingView/BlockingViewContainer';
import { FormView } from '../../Components/FormView/FormView';
import { PaymentViewRenderer } from '../../Components/PaymentView/PaymentViewRenderer';
import { PriceUpdateStepContainer } from '../../Components/PriceUpdateStep/PriceUpdateStepContainer';
import { QuoterViewContainer } from '../../Components/QuoterView/QuoterViewContainer';
import { SubmissionViewContainer } from '../../Components/SubmissionView/SubmissionViewContainer';
import { SummaryViewContainer } from '../../Components/SummaryView/SummaryViewContainer';
import { useStep } from '../../Hooks/useStep';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { PlanFinderLayout } from '../../Layouts/PlanFinder/Layout';
import { TransactionFlowLayout } from '../../Layouts/TransactionFlow/Layout';
import { shortLocale } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { CustomVariantWrapper } from './CustomVariantWrapper';

export type Props = {
  progress: number;
  displayErrors: boolean;
  popup: ReactElement | null;
  isLoading: boolean;
  showLoadingPage: boolean;
  onBackButtonClick: () => void;
  layout: ConsumerFlow.Layout;
  landingStepsIds: string[];
  questionnaireEngine: QuestionnaireEngine;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  blueprint: QuestionnaireBlueprint;
  setShouldRefreshApplicationPremium: (shouldRefreshApplicationPremium: boolean) => void;
} & ResponsiveProps &
  StepProps;

const FULL_WIDTH_LAYOUT_SUBSECTIONS = [SubsectionVariant.productSummary, SubsectionVariant.summary];

function isFullWidthVariant(variant: SubsectionVariant | undefined): boolean {
  if (!variant) return false;
  return FULL_WIDTH_LAYOUT_SUBSECTIONS.includes(variant);
}

export function StepPageBase(props: Props): ReactElement | null {
  const {
    questionnaireEngine,
    displayErrors = false,
    step,
    progress,
    popup,
    showLoadingPage,
    onBackButtonClick,
    layout,
    landingStepsIds,
    onAnswerComplete,
    onInfoIconClick,
    onError,
    submitAnswers,
    nodeIdToAnswerPathMap,
    blueprint,
  } = props;

  const {
    setLocalVersionedAnswers,
    localVersionedAnswers,
    setSubmitFormRequested,
    renderingQuestionnaire,
    renderingStep,
    onSubmit: onSubmitHook,
    onAnswerChange,
  } = useStep(questionnaireEngine, displayErrors, submitAnswers);

  const [isNextBtnLoading, setIsNextBtnLoading] = useState(false);

  useEffect(() => {
    setIsNextBtnLoading(false);
  }, [renderingStep.id]);

  useEffect(() => {
    const scrollToTopOnLoad = layout === ConsumerFlow.Layout.planFinder;

    if (!showLoadingPage && scrollToTopOnLoad) {
      document.getElementById('root')?.scrollIntoView();
    }
  }, [showLoadingPage, layout]);

  const onSubmit = useCallback(() => {
    if (isNextBtnLoading) return;

    setIsNextBtnLoading(true);

    if (onSubmitHook()) return;

    setIsNextBtnLoading(false);
  }, [isNextBtnLoading, onSubmitHook]);

  const layoutLookup = {
    [ConsumerFlow.Layout.planFinder]: PlanFinderLayout,
    [ConsumerFlow.Layout.transactionFlow]: TransactionFlowLayout,
    [ConsumerFlow.Layout.centered]: CenteredLayout,
  };

  const FlowLayout = layoutLookup[layout];
  if (!FlowLayout) throw new Error('Layout not found');

  const onApply = useCallback((): void => {}, []);

  const onUseNeedsAnalysis = useCallback(
    (useNeedsAnalysis: boolean): void => {
      setLocalVersionedAnswers((prevVersionedAnswers) => {
        return prevVersionedAnswers;
      });

      if (useNeedsAnalysis) {
        setSubmitFormRequested(true);
      }
    },
    [questionnaireEngine, setLocalVersionedAnswers, setSubmitFormRequested],
  );

  const language = shortLocale();

  const formProps = {
    renderingQuestionnaire,
    renderingStepId: renderingStep.id,
    onSubmit: (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    },
    onInfoIconClick,
    language,
    onAnswerComplete,
    onBackButtonClick,
    landingStepsIds,
    onError,
    onAnswerChange,
    nextStepButtonText: renderingStep.nextStepButtonText,
    onUseNeedsAnalysis,
    isNextBtnLoading,
  };

  let hideHeaderOnDesktop = false;

  let content: ReactElement;
  switch (renderingStep.variant) {
    case SubsectionVariant.quoter:
      const quoterVariantOptions = (renderingStep as QuoterRenderingSubsection).variantOptions;
      const simpleQuoter = quoterVariantOptions?.simpleQuoter;

      return (
        // TODO: Support all available layouts
        <QuoterViewContainer
          isSimpleQuoter={!!simpleQuoter}
          title={renderingStep.title}
          subtitle={renderingStep.text}
          onBack={onBackButtonClick}
          selfServeBlockedIf={(renderingStep as QuoterRenderingSubsection).selfServeBlockedIf}
          onApply={onApply}
          onCoverageAmountChange={() => {}}
          onUseNeedsAnalysis={onUseNeedsAnalysis}
        />
      );
    case SubsectionVariant.summary:
      const summaryProps = {
        renderingStep,
        language,
        onBackButtonClick,
        landingStepsIds,
        onNextClick: onSubmit,
        isNextBtnLoading,
      };

      content = <SummaryViewContainer {...summaryProps} />;
      break;
    case SubsectionVariant.productSummary:
      content = <div>TODO: product summary was only supporting legacy product structure</div>;
      break;
    case SubsectionVariant.priceUpdateWarning:
      const priceUpdateStep = renderingStep as PriceUpdateWarningRenderingSubsection;

      content = (
        <CustomVariantWrapper formViewProps={formProps} useCustomNavigation>
          <PriceUpdateStepContainer
            onChangeAnswer={onBackButtonClick}
            onContinue={onSubmit}
            useApplicationMonthlyPremium={priceUpdateStep.variantOptions?.useApplicationMonthlyPremium}
            nextStepButtonText={renderingStep.nextStepButtonText}
            isNextBtnLoading={isNextBtnLoading}
          />
        </CustomVariantWrapper>
      );
      break;
    case SubsectionVariant.addon:
      content = (
        <AddonsViewContainer
          onBackButtonClick={onBackButtonClick}
          onNextClick={onSubmit}
          onAnswerChange={onAnswerChange}
          nodeIdToAnswerPathMap={nodeIdToAnswerPathMap}
          blueprint={blueprint}
          answers={localVersionedAnswers.v1}
          answersV2={localVersionedAnswers.v2}
          language={language}
          nextStepButtonText={renderingStep.nextStepButtonText}
          isNextBtnLoading={isNextBtnLoading}
        />
      );
      break;
    case SubsectionVariant.payment:
      return <PaymentViewRenderer onNextClick={onSubmit} onPreviousClick={onBackButtonClick} />;
    case SubsectionVariant.submission:
      // TODO: redirect back to payment step if a setup intent hasn't been confirmed for the customer

      // TODO: Support all available layouts
      const { variantOptions } = renderingStep as SubmissionRenderingSubsection;

      const layout = variantOptions?.layout;
      const hideHeader = variantOptions?.hideHeader;
      hideHeaderOnDesktop = !!hideHeader;

      const submissionViewContainer = <SubmissionViewContainer language={language} layout={layout} />;
      if (layout === SubmissionSubsectionLayout.flow) {
        // Flow layout stays in the existing layout, set step data as `content` rather than returning.
        content = submissionViewContainer;
      } else {
        return submissionViewContainer;
      }

      break;
    case SubsectionVariant.blocking:
      // TODO: Support all available layouts
      return <BlockingContainer language={language} />;
    default:
      content = <FormView {...formProps} />;
  }

  return (
    // TODO: Fix typing/instantiation issue
    // @ts-ignore JSX element type 'FlowLayout' does not have any construct or call signatures
    <FlowLayout
      title={renderingStep.title}
      subtitle={renderingStep.text}
      questionId={step.id}
      progress={progress}
      hideHeaderOnDesktop={hideHeaderOnDesktop}
      popup={popup}
      isLoading={showLoadingPage}
      onBackButtonClick={onBackButtonClick}
      useEngine={true}
      isFullWidthLayout={isFullWidthVariant(renderingStep.variant)}
      offsetContent={step.iconName ? <Icon name={step.iconName} size='60px' /> : undefined}
    >
      {content}
    </FlowLayout>
  );
}

export const StepPage = withResponsive(StepPageBase);
