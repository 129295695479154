import { FormEvent, ReactNode, ReactElement } from 'react';

import { getAllQuestions, OnAnswerChange, RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import { NavigationButtons } from '../NavigationButtons/NavigationButtons';

import { text } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { Props as StepPageProps } from '../../Pages/StepPage/StepPage';

export type Props = Partial<StepProps> &
  Partial<StepPageProps> & {
    renderingQuestionnaire: RenderingQuestionnaire;
    renderingStepId: string;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    onAnswerChange: OnAnswerChange;
    onAnswerComplete: (fieldId: string) => void;
    onInfoIconClick?: () => void;
    language: Language;
    children?: ReactNode;
    nextStepButtonText?: string;
    iconMap?: Record<string, string>;
    isNextBtnLoading: boolean;
  };

export function FormView(props: Props): ReactElement | null {
  const {
    renderingQuestionnaire,
    onSubmit,
    onBackButtonClick,
    landingStepsIds,
    renderingStepId,
    nextStepButtonText,
    isNextBtnLoading,
  } = props;

  const questions = getAllQuestions(renderingQuestionnaire);

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      {questions.map(() => undefined)}
      {props.children}
      <NavigationButtons
        onPreviousClick={onBackButtonClick ? onBackButtonClick : () => {}}
        hidePrevious={!!landingStepsIds?.includes(renderingStepId)}
        hideNext={false}
        nextButtonText={nextStepButtonText || text('continue')}
        onNextClick={() => {}}
        isNextBtnLoading={isNextBtnLoading}
      />
    </form>
  );
}
