import { ReactElement } from 'react';

import { ReactHtmlParser, Text } from '@breathelife/ui-components';

import { text } from '../../Localization/Localizer';

export function ContactInfo(props: { phoneNumber: string; email?: string }): ReactElement {
  const { phoneNumber, email } = props;
  return (
    <Text>
      <ReactHtmlParser
        html={text(`submitApplication.contactInfo.moreInfo`, {
          phoneNumber,
          email,
        })}
      />
    </Text>
  );
}
