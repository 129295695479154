import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { NodeIdToAnswerPathMap, OnAnswerChange, PivotAnswerResolver } from '@breathelife/questionnaire-engine';
import { Language, Answers, VersionedAnswers, QuestionnaireBlueprint } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import * as applicationOperations from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { AddonsView } from './AddonsView';

type Props = {
  onBackButtonClick: () => void;
  onAnswerChange: OnAnswerChange;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  blueprint: QuestionnaireBlueprint;
  answers: Answers;
  answersV2: Answers;
  onNextClick: () => void;
  language: Language;
  nextStepButtonText?: string;
  isNextBtnLoading: boolean;
};

export function AddonsViewContainer(props: Props): ReactElement | null {
  const dispatch = useDispatch();

  const applicationState = useCxSelector((store) => store.consumerFlow.insuranceApplication);
  const applicationId = applicationState?.insuranceApplication?.id;
  const addonData = applicationState?.addonsMetadata;
  const monthlyPremium = applicationState?.insuranceApplication?.monthlyPremium;
  const referencePremium = applicationState?.referencePremium;

  const answersResolver = useMemo(
    () =>
      PivotAnswerResolver.from(
        new VersionedAnswers({ v1: props.answers, v2: props.answersV2 }),
        props.nodeIdToAnswerPathMap ?? new Map(),
        props.blueprint,
        'v1',
      ),
    [props.answers, props.answersV2, props.blueprint, props.nodeIdToAnswerPathMap],
  );

  const selectedAddons: string[] = addonData
    ? answersResolver.usingNodeId().getAnswer(addonData.addonNodeId, {}) ?? []
    : [];

  useEffect(() => {
    if (typeof applicationId === 'undefined') return;
    dispatch(applicationOperations.getAddons(applicationId));
  }, [dispatch, applicationId]);

  if (applicationState.isLoading) {
    return <Loader />;
  }

  if (!addonData) return null;

  return (
    <AddonsView
      onBackButtonClick={props.onBackButtonClick}
      onNextClick={props.onNextClick}
      language={props.language}
      addons={addonData.addons}
      selectedAddons={selectedAddons}
      onAddonsChange={() => {}}
      monthlyPremium={monthlyPremium}
      referencePremium={referencePremium}
      nextStepButtonText={props.nextStepButtonText}
      isNextBtnLoading={props.isNextBtnLoading}
    />
  );
}
