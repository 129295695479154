import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { IconName } from '@breathelife/types';
import { Text, Icon, ReactHtmlParser } from '@breathelife/ui-components';

import { text } from '../../Localization/Localizer';

export function DownloadWidget(props: { url: string; document: string }): ReactElement {
  const { url, document } = props;

  return (
    <Box mb={2} display='flex' alignItems='center'>
      <Box width='48' mr={1}>
        <Icon name={IconName.fileDownload} size='48' />
      </Box>
      <Text variant='small-copy' noSpacing>
        <ReactHtmlParser html={text(`submitApplication.download.${document}`, { url })} />
      </Text>
    </Box>
  );
}
